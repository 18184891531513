import imgMobileAvailabilities from '@assets/images/mobile-availabilities.png'
import imgMobilePlanning from '@assets/images/mobile-planning.png'
import pictoExtra from '@assets/svg/picto-handle-hours-extra.svg'
import pictoHuman from '@assets/svg/picto-handle-human.svg'
import pictoLeaves from '@assets/svg/picto-handle-leaves.svg'
import pictoSick from '@assets/svg/picto-handle-sick.svg'
import pictoHours from '@assets/svg/picto-hours-auto.svg'
import pictoPlanning from '@assets/svg/picto-planning.svg'
import pictoTransfert from '@assets/svg/picto-transfert.svg'

const functionalities = [
    {
        id: 1,
        subTitle: 'Améliorez le pilotage de votre planning global',
        title: 'Gestion des temps',
        image: pictoPlanning,
        list: [
            'Créez des roulements types et assignez-les à une ou plusieurs personnes',
            'Les modifications de planning sont mises à jour automatiquement et en temps réel en fonction des absences et des remplacements',
            "Recevez des alertes en cas de non-conformité avec votre convention collective ou vos accords d'entreprise",
        ],
        link: '/lp/planning-personnel/gestion-temps',
    },
    {
        id: 2,
        subTitle: 'Créez facilement des plannings',
        title: 'Gestion des horaires',
        image: pictoHours,
        list: [
            "Construisez des plannings fiables grâce à l'intelligence artificielle et au machine learning",
            'Intégrez les préférences de planning de vos collaborateurs pour améliorer leur qualité de vie au travail',
            'Utilisez le système de pointage pour ajuster automatiquement le planning',
        ],
        link: '/lp/planning-personnel/gestion-horaires',
    },
    {
        id: 3,
        subTitle: 'Récupérez les demandes de congés',
        title: 'Gestion des congés',
        image: pictoLeaves,
        list: [
            'Soyez informé rapidement des demandes de congés de vos salariés',
            'Acceptez-les ou refusez-les directement via Staffea',
            'Le planning se met automatiquement à jour en intégrant les congés',
        ],
        // link: '#',
    },
    {
        id: 4,
        subTitle: 'Recevez instantanément les arrêts maladies',
        title: 'Gestion des absences',
        image: pictoSick,
        list: [
            'Récupérez les arrêts maladies de vos salariés de façon dématérialisée',
            'Le planning se met automatiquement à jour en fonction des absences',
        ],
        // link: '#',
    },
    {
        id: 5,
        subTitle: 'Intégrez directement les heures supplémentaires',
        title: 'Gestion des heures supplémentaires',
        image: pictoExtra,
        list: [
            'Transférez les heures réellement effectuées à votre logiciel des temps de travail',
            'Calcul automatique des éléments variables de paie',
        ],
        // link: '#',
    },
    {
        id: 6,
        subTitle: 'Trouvez facilement des remplaçants',
        title: 'Gestion des remplacements',
        image: pictoTransfert,
        list: [
            "Publiez vos opportunités de mission sur l'application mobile de planning",
            "Les potentiels remplaçants habilités de votre réseau sont interrogés par l'application",
            "Dématérialisez les démarches de recrutement grâce à la signature électronique des contrats de travail et d'intérim",
        ],
        // link: '#',
    },
    {
        id: 7,
        subTitle: 'Facilitez votre gestion administrative',
        title: 'Gestion du personnel',
        image: pictoHuman,
        list: [
            "Tous vos besoins métiers sont répertoriés dans le logiciel d'organisation de planning",
            "Les plannings s'ajustent automatiquement pour vous faire gagner du temps",
            "Le logiciel s'assure que votre planification est conforme à vos besoins en personnel",
        ],
        // link: '#',
    },
    {
        id: 8,
        subTitle: 'Une app agile, efficace et sécurisée',
        title: 'Application mobile de planning',
        images: [
            { image: imgMobilePlanning, alt: 'Staffea, application mobile consultation du planning' },
            { image: imgMobileAvailabilities, alt: 'Staffea, application mobile affichage des disponibilités' },
        ],
        list: [
            'Vos collaborateurs consultent leur planning en temps réel, mois par mois',
            "Ils peuvent demander des échanges et des ajustements d'horaires",
            'Ils indiquent leurs disponibilités et leurs indisponibilités pour éviter les sur sollicitations',
        ],
        linkText: "L'application mobile pour vos salariés",
        // link: '#',
    },
]

export default functionalities
