import automatic from '@assets/svg/illustration-automatic.svg'
import live from '@assets/svg/illustration-live.svg'
import noPaper from '@assets/svg/illustration-nopaper.svg'

const mission = [
    {
        id: 1,
        title: 'Dématérialisation',
        content: [
            "Avec Staffea, entrez dans l'ère du 100% dématérialisé ! Intégrez les membres du personnel dans le logiciel, avec leur contrat de travail, leurs qualifications et leur mobilité. Vos démarches de recrutement sont facilitées avec la signature électronique des contrats de travail et contrats d'intérim.",
            "Toutes les demandes de congés, la transmission des arrêts maladies et le suivi des heures supplémentaires sont gérés directement sur l'application Staffea. Votre gestion administrative est considérablement simplifiée !",
        ],
        svg: noPaper,
    },
    {
        id: 2,
        title: 'Gestion en temps réel',
        content: [
            "Grâce à notre logiciel de gestion de planning d'entreprise, bénéficiez de mises à jour automatiques pour vous faire gagner du temps. L'ajustement des horaires de chaque collaborateur se fait en temps réel.",
            "Vos collaborateurs ont accès à leur planning depuis l'application Staffea. Ils peuvent le consulter, faire des demandes de modification, ajouter leurs disponibilités ou encore accepter des remplacements. Simple et efficace !",
        ],
        svg: live,
    },
    {
        id: 3,
        title: 'Automatisation',
        content: [
            "L'intelligence artificielle et le machine learning font de Staffea un logiciel de gestion de planning RH flexible et efficace, avec des mises à jour automatiques.",
            "Une absence d'un collaborateur ? Le planning recherche des remplaçants. Un remplacement pourvu ? Le planning se met à jour. Des heures supplémentaires ? Le planning calcule les éléments variables de paie.",
            'En cas de non-conformité de votre planification, vous recevez immédiatement une alerte pour modifier votre planning.',
        ],
        svg: automatic,
    },
]

export default mission
