const specificities = [
    {
        id: 1,
        color: 'var(--chakra-colors-green-500)',
        name: 'Santé',
        description:
            "Les établissements de santé ont enfin un logiciel de planning du personnel adapté à leurs besoins. Ergonomique, sécurisé et flexible, il s'appuie sur l'intelligence artificielle pour intégrer des roulements pour chaque collaborateur, dans le respect de vos conventions collectives. Moins de charge mentale pour vous et plus de bien-être pour votre personnel !",
        fields: ['Ehpad', 'hôpitaux', 'cliniques', 'soins à domicile', 'laboratoire'],
    },
    {
        id: 2,
        color: 'var(--chakra-colors-yellow)',
        name: 'Services',
        description:
            "Optimisez la gestion administrative de votre entreprise de services en utilisant Staffea. Orchestrez les plannings de tous vos collaborateurs avec une solution 100% web, qui s'interface avec vos logiciels RH et métiers. Gérez en un clic les demandes de congés, les absences, les remplacements et les heures supplémentaires !",
        fields: ['Restauration', 'hôtellerie', 'distribution', 'sécurité', "centres d'appel"],
    },
    {
        id: 3,
        color: 'var(--chakra-colors-orange)',
        name: 'Industrie',
        description:
            "Ne perdez pas une minute en utilisant notre logiciel de gestion RH adapté aux besoins de l'industrie. Gérez vos plannings de chantier facilement avec des horaires types pour vos ouvriers, en fonction de vos besoins de production. Staffea contrôle automatiquement vos plannings pour s'assurer de leur conformité et vous avertir en cas d'anomalie.",
        fields: ['Production industrielle', 'logistique', 'génie civil'],
    },
]

export default specificities
