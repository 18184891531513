import imgHR from '@assets/images/img-HR.png'
import imgUser from '@assets/images/img-handle-all-optimize-experience.png'
import imgTransfer from '@assets/images/img-less-admin-transfert.png'
import imgTsqPlanning from '@assets/images/img-tailor-intelligent-planning.png'
import imgPlanning from '@assets/images/img-uptodate-planning.png'

const content = [
    {
        id: 1,
        header: 'Créez des plannings sur mesure',
        title: "Avec Staffea, l'organisation de planning devient un jeu d'enfant !",
        text: "Construisez des plannings robustes grâce à l'intelligence artificielle et au machine learning.  Les préférences de vos collaborateurs sont prises en compte et intégrées au planning global. Tous vos besoins métiers sont répertoriés dans Staffea.",
        image: imgTsqPlanning,
        alt: "Staffea, application web écran d'automatisation des plannings",
    },
    {
        id: 2,
        header: 'Gérez vos plannings en temps réel',
        title: 'Au bureau ou en télétravail, avec Staffea, gérez vos plannings en un clic !',
        text: 'Notre logiciel de planning du personnel est toujours à jour et vous offre une vue en temps réel de la présence de vos équipes grâce au système de pointage. Vous êtes notifié instantanément des absences et le module de gestion des remplacements interroge immédiatement les personnes disponibles habilitées au sein de votre réseau en fonction des compétences et des habilitations nécessaires sur le poste vacant.',
        image: imgPlanning,
        alt: 'Staffea, application web écran de gestion du planning des managers',
    },
    {
        id: 3,
        header: 'Réduisez vos tâches administratives',
        title: "Limitez les impacts de l'absentéisme de vos équipes.",
        text: 'Recevez de façon dématérialisée les arrêts maladies de vos salariés et soyez plus réactifs pour trouver des remplaçants. La solution de remplacement intégrée de Staffea interroge les potentiels remplaçants habilités au sein de votre réseau. De plus, notre logiciel se connecte à votre solution de paie pour faire remonter les heures effectuées automatiquement.',
        image: imgTransfer,
        alt: 'Staffea, application web écran de gestion des remplacements',
    },
    {
        id: 4,
        header: 'Sécurisez vos dispositifs RH',
        title: 'Soyez serein, avec Staffea, vos plannings sont toujours en conformité réglementaire !',
        text: "Staffea contrôle automatiquement vos plannings afin de s'assurer de leur conformité aux obligations de votre secteur. Le logiciel édite les contrats de travail par le biais de vos modèles de contrats types. Il valide également les heures effectuées avec vos équipes pour éviter les contestations. Nous vous garantissons le respect du RGPD grâce à une solution française 100% indépendante des GAFAM.",
        image: imgHR,
        alt: 'Staffea, application web écran de gestion des contrats employés',
    },
    {
        id: 5,
        header: "Optimisez l'expérience collaborateur",
        title: 'Avec Staffea, fidélisez vos collaborateurs grâce à un outil réellement utile au quotidien.',
        text: "Notre logiciel de planning du personnel offre une meilleure ergonomie et une application mobile pour être disponible partout, tout le temps. Elle permet de dématérialiser la transmission des arrêts maladie, facilite les modifications d'horaires ou les interversions entre 2 salariés. Elle dispose également d'un espace de commentaires pour que les équipes terrains vous partagent leur expérience et remontent d'éventuels événements indésirables.",
        image: imgUser,
        alt: 'Staffea, application mobile multifonctionnelle',
    },
]

export default content
